import { createElement } from 'react'

import { twMerge } from '@utils/cn.ts'

import type { ReactNode } from 'react'
import type React from 'react'

function Skeleton({
  children,
  className,
  inline,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & {
  readonly inline?: boolean
  readonly children?: ReactNode
}) {
  return createElement(
    inline ? 'span' : 'div',
    {
      ...props,
      className: twMerge(
        `
          tw-w-full tw-animate-pulse tw-cursor-wait tw-select-none tw-rounded-md
          tw-bg-primary-lightgrey tw-text-transparent
        `,
        inline && 'tw-inline-block',
        className,
      ),
    },
    children ?? 'loading',
  )
}

export { Skeleton }
